@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@700&family=Inter&family=Montserrat:wght@200&family=Playfair+Display:ital@1&family=Poiret+One&family=Poppins:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700&display=swap');

.mainContainerLogin{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
}
.mainContainerLogin h3{
    font-weight: 600;
    font-size: 30px;
    color: #764abc;
    /* color: #f34225; */
    

}
.LoginFormContainer{
    width: 30%;
    padding: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
@media (max-width: 1024px) {
    .LoginFormContainer {
      width: 90%;
    }
  }
@media (max-width: 1024px) {
    .LoginFormContainer {
      width: 90%;
    }
  }


.inputValuesUser{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px 10px 0 0;
    background-color: #d9d9d9;
    padding: 20px;
}
.UsernameUSER{
    display: flex;
    flex-direction: column;
    

}
.UsernameUSER input{
    padding: 10px 15px;
    border: 0.5px solid gray;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
}
.UserPasswordUser{
    display: flex;
    flex-direction: column;

}
.UserPasswordUser input{
    padding: 10px 15px;
    border: 0.5px solid gray;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;

}
.UserLoginBtn button{
    width: 100%;
    padding: 10px 15px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    background-color: #764abc;
    color: white;
    border: none;   
    transition: 0.3s ease-in;
    cursor: pointer;
}
.UserLoginBtn button:hover{
    background-color: white;
    color: #764abc;
    border: 0.5px solid gray;
    transition: 0.2s ease-out;

}
.ForgetPasswordUser{
    padding: 20px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    border-bottom: 8px solid #764abc;
}
.ForgetPasswordUser p{
    font-size: 16px;
    font-weight: 600;
    color: #764abc;
    cursor: pointer;
}
.error{
    color: red;
}