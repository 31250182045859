.tableRow {
  display: flex;
  justify-content: space-between;
  padding: 8px; 
}

.tableHeader,
.tableCell {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  border: 1px solid rgb(230, 216, 216, 0.4);  
}

label{
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHeader{
  font-weight: bold;
}

.tableHeader:first-child,
.tableCell:first-child{
  min-width: 80px;
}


.tableCell input {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.tableCell input:disabled {
  opacity: 0.9; 
  cursor: not-allowed;
}


.tableRow label {
  cursor: pointer;
}

.tableCell input[type="checkbox"]:checked {
  color: aqua;
}








@media (max-width: 1024px) {
  .housekeepingTable {
    /* display: none; */
  }

  label {
    background-color: rgb(167, 196, 196);
    min-width: 50px;
    display: flex;
    min-height: 60px;
    font-size: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  
  

  .tableRow {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    /* grid-auto-rows: minmax(100px, auto);*/
    gap: 8px;
    place-items: center;
    justify-items: center;
  }

  .tableCell{
    display: none;
  }

}
