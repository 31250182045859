.formContainer {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  width: 85%;
  border: 2px solid black;
  font-family: 'Times New Roman', Times, serif;
}

.heading {
  border-bottom: 1px solid black;
  text-align: center;
  text-decoration: underline;
}

.Calender {
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 12px;
}

.calendarDropdown{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.Calender input {
  padding: 5px 5px;
  border-radius: 5px;
  border: 0.5px solid gray;
}

.calendarIcon {
  position: relative;
  display: inline-block;
}





.Schoolid {
  display: flex;
  align-items: center;
  gap: 25px;
  border-bottom: 1px solid black;
  padding-left: 12px;
}

.container_input{
  position: relative;
}

.container_input svg{
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  padding: 2px;
}

.container_input svg:hover{
 background-color: #4554c654;

}

.Schoolid input {
  padding: 5px 5px;
  border-radius: 5px;
  border: 0.5px solid gray;
}

.Schoolid button {
  cursor: pointer;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background-color: rgb(42, 87, 155);
  color: white;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  transition: 0.3s ease-in-out;
}

.Schoolid button:hover {
  background-color: rgb(39, 64, 102);

}

.schoolname {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid black;
  padding-left: 12px;
}

.DistBlock {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  gap: 10px;
  padding-left: 12px;
}

.housekeepingTable {
  border: 1px solid #ccc;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  padding: 8px; 
}

.tableHeader,
.tableCell {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  border: 1px solid rgb(230, 216, 216, 0.4);  
}

label{
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHeader{
  font-weight: bold;
}

.tableHeader:first-child,
.tableCell:first-child{
  min-width: 80px;
}


.tableCell input {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.tableCell input:disabled {
  opacity: 0.9; 
  cursor: not-allowed;
}


.tableRow label {
  cursor: pointer;
}

.tableCell input[type="checkbox"]:checked {
  color: aqua;
}




/* .tableCell input:checked::before {
  content: ''; 
  display: block;
  width: 50%;
  height: 25%; 
  border-bottom: 3px solid #fff; 
  border-right: 3px solid #fff; 
  transform: rotate(-45deg); 
  margin-left: 25%; 
  margin-top: 10%;
} */


.submitbtn {
  margin: 8px auto;

}

.submitbtn button {
  cursor: pointer;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background-color: rgb(42, 87, 155);
  color: white;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  transition: 0.3s ease-in-out;
  width: 100px;
}

button:hover {
  background-color: rgb(39, 64, 102);

}

.NoToilets {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-left: 12px;
}

.NoToilets input {
  padding: 5px 5px;
  border-radius: 5px;
  border: 0.5px solid gray;
}

.mobileTable {
  max-width: 820px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  margin: 10px;
  padding: 10px;
  margin: 20px;
  border: 1px solid rg b(0, 0, 0);
  border-radius: 10px;
  gap: 12px;
  /* max-width: 500px;
  display: flex;
  flex-wrap: wrap; */
}

.ItemContainer {
  border: 1px solid black;
  display: flex;
  border-radius: 8px;
  justify-content: center;

}

.ItemContainer p {
  padding: 8px;

  border-radius: 50%;

  cursor: pointer;
}



.disabled {
  opacity: 0.7;
  cursor: not-allowed; 
  pointer-events: none;
}


.print_container {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  text-align: center;
  margin-bottom: 10px;
  align-items: flex-end;
  padding: 8px 12px;
  background-color: #4554c6;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.print_container .button_withIcon {
  gap: 8px; /* Increased gap for better spacing */
  display: flex;
  align-items: center;
  justify-content: center;
}

.print_container:hover {
  background-color: #2525c9;
}


@media (max-width:480px) {
  .mobileTable {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .Schoolid {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;

  }

  .Schoolid button {
    padding: 2px 4px;
    font-size: 12px;
  }

  .Schoolid input {
    width: 150px;
    padding: 2px 2px;
  }

  .Calender {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    
  }

  .Calender svg  {
   /* background-color: aquamarine;  */
   position: absolute;
   right: 0.3rem;
   font-size: 8px;
  }

  .Calender input {
    padding: 2px 4px;
    font-size: 16px;
    border-radius: 5px;
    border: 0.5px solid gray;
    position: relative;
  }

  .calendarIcon {
    position: relative;
    display: inline-block;
  }

  .schoolname {
    font-size: 12px;
  }

  .DistBlock {
    font-size: 12px;
  }

  .NoToilets {
    font-size: 12px;
  }
}

@media (max-width: 315px) {
  .Schoolid,
  .Calender,
  .schoolname,
  .DistBlock,
  .NoToilets {
    flex-direction: column;
    padding: 4px 12px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .Schoolid button,
  .NoToilets button {
    padding: 4px 12px;
    display: inline;
  }

  h4 {
    padding: 0;
    margin: 0;
  }

  .NoToilets {
    gap: 0;
  }
}


.NoToiletsMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.NoToiletsMobile input {
  padding: 5px 5px;
  border-radius: 5px;
  border: 0.5px solid gray;
}



@media (min-width: 1025px) {
  .h3Mobile {
    display: none;
  }

  .mobileTable {
    display: none;
  }

  .NoToiletsMobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .housekeepingTable {
    /* display: none; */
  }

  label {
    background-color: rgb(167, 196, 196);
    min-width: 50px;
    display: flex;
    /* justify-content: space-around; */
    /* align-items: center; */
    min-height: 60px;
    font-size: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  
  .checkedLabelActive {
    background-color: rgb(49, 174, 24);
    position: relative;
  }

  .checkedLabelNotActive {
    background-color: rgb(26, 159, 159);
    /* opacity: 0.7;  */
    color: red; 
  }

  .uncheckedLablActive{
    background-color: blue;
    color:yellow;
    position: relative;
  }

  .disabledLabel {
    opacity: 0.5;
    cursor: not-allowed;
    position: relative;
    background-color: rgb(167, 196, 196);
    color: black;
  }

  .checkedLabelActive::before {
    content: "✅";
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    padding: 4px; 
    border-radius: 50%; 
    /* opacity: 0.5; */
  }
  .uncheckedLablActive::before {
    content: "❌";
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    padding: 4px; 
    border-radius: 50%; 
    /* opacity: 0.5; */
  }

  
  .disabledLabel::before {
    content: "✕";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    color: black; 
    padding: 4px; 
    border-radius: 50%; 
    opacity: 0.5;
  }
  
  .tableHeader{
    display: none;
  }

  .tableRow {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    /* grid-auto-rows: minmax(100px, auto);*/
    gap: 8px;
    place-items: center;
    justify-items: center;
  }

  .tableCell{
    display: none;
  }

  .Schoolid button,
  .NoToilets button {
    padding: 4px 12px;
    display: inline;
  }
}



.inputSign {
  height: 100%;
  width: 100%;
}

.UpdateCheck {
  border-top: 0.5px solid gray;
  width: 100%;
  text-align: center;
  padding: 10px 0 10px 0;
}