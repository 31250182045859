.mainContainer{
  max-width: 95%;
  border: 1px solid black;
  margin: 50px auto;
  border-radius: 10px;
  padding: 20px;
}
.textCenter{
 display: flex;
 justify-content: center;
}
/* Date pick */
.DatePick{
  margin: 10px; 
  display: flex;
  align-items: center;
  gap: 2%;
}


/* YourStylesheet.module.css */

.datePickerContainer {
  display: flex;
  align-items: center;
}

.datePicker {
  margin-right: 10px; /* Add spacing between the DatePicker and the icon */
  padding : 5px 10px;
  border-radius: 5px;
  border: 0.5px solid gray;
}

.calendarIcon {
  position: relative;
  color: #007bff; /* Customize the color of the calendar icon */
  font-size: 1.5rem; /* Adjust the font size of the icon */
}



/* end  */

/* Input and button  */
.InputGroup {
  display: flex;
  margin: 10px; 
  gap: 2%;
}


.inputText {
  border-radius: 5px;
}

.buttonPrimary {
  background-color: #007bff; 
  color: #fff; 
  border: none;
  border-radius: 5px;
  padding: 8px 16px; 
  cursor: pointer;
}
/* input button end */

/* Table style css */
.Table{
  width: 100%;
}
th, td {
  border-bottom: 1px solid #ddd;
  text-align: center;
}
@media (max-width: 1024px) {
  .mainContainer{
    border: none;
  }
  .HR{
    display: none;
  }
}
@media (max-width: 1024px){
  .Table{
    display: none;
  }
}
.ClearMonthBtn button{
  padding: 5px 10px;
  background-color: #007bff;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  color: white;
  outline: none;
  border: none;
  border-radius: 4px;
}
.ClearMonthBtn button:hover{
  opacity: 0.7;
}

/* mobile table */

.MobileContainerAdmin {
  margin: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

/* Mobile Styles */
@media (max-width: 1024px) {
  .MobileContainerAdmin{
      margin: 4px;
  }
  th, td {
    font-size: 12px;
  }
}

@media (min-width: 1025px) {
  .MobileContainerAdmin {
      display: none;
  }
 
  
}
@media (max-width: 409px){
  .ClearMonthBtn button{
    padding: 4;
    font-size: 12px;
  }
}
@media (max-width:322px){
 .DatePick{
  display: flex;
  flex-direction: column;
 }
}
