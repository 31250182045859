.mainContainer {
  border: 2px solid black;
  margin: 10px 100px;

  
}

.PDFFULL {
  margin: 10px;
}

p {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.heading {

  border-bottom: 1px solid black;
  text-align: center;
  text-decoration: underline;
}

.BillingMonth {

  border-bottom: 1px solid black;
}

.schoolId {

  border-bottom: 1px solid black;
}

.SchoolName {

  border-bottom: 1px solid black;
}

.distBlock {

  border-bottom: 1px solid black;
}

.table {

  border-bottom: 1px solid black;

}

.PresentAbsent {

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 150px;
  align-items: flex-end;
  padding-bottom: 10px;
}

.presenetText {
  padding-left: 20px;
  align-items: center;
  display: flex;
  gap: 20px;
}


.housekeepingTable {
  border: 1px solid #ccc;



}

.housekeepingTableHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.housekeepingColumnsContainer {
  display: flex;
}

.housekeepingColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.housekeepingColumn:last-child {
  border-right: none;
}



.housekeepingTable {
  width: 100%;
  border-collapse: collapse;

}

.housekeepingTable thead th {
  background-color: #f2f2f2;
  border: 1px solid #dddddd;
  text-align: left;

}

.housekeepingTable tbody td {
  border: 1px solid #dddddd;
  text-align: left;

}

.housekeepingTable {
  width: 100%;
  border-collapse: collapse;
  border-right: 1px solid black;
}

.housekeepingTable th,
.housekeepingTable td {
  font-family: 'Times New Roman', Times, serif;
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.inputSign {
  height: 100%;
  width: 100%;
}

.Principal {
  padding-right: 100px;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  /* .mainContainer {
    width: 21cm;
    height: 29.7cm;
    margin: 2cm auto;
    border: 1px solid #ccc;
    overflow: hidden;
    
  } */



  /* Add styles for other components as needed */
  .heading h3 {
    font-size: 18px;
  }

  .BillingMonth p,
  .schoolId p,
  .SchoolName p,
  .distBlock p {
    font-size: 14px;
  }

  .housekeepingTable table {
    width: 100%;
  }

  /* Adjust other styles as needed */

  .PresentAbsent,
  .AreaOfficer,
  .Principal {
    font-size: 14px;
  }
}

/* tr td:first-child{
  min-width: 70px;
  max-width: 70px;
} */

tr.page-break {
  page-break-before: always;
}

@media print {

  /* Example: Adjusting overflow for printed tables */
  table {
    overflow: visible !important;
  }
}

.BillingMonth,
.schoolId,
.SchoolName,
.distBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 12px;
}

.Calender {
  border: 1px solid black;
  /* border-bottom: none; */
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin: 0 100px;
  padding-bottom: 10px;
}

.Schoolid {
  display: flex;
  align-items: center;
  border: 1px solid black;
  justify-content: center;
  gap: 20px;
  margin: 0 100px;
  margin-bottom: 10px;

}

.Schoolid button {
  cursor: pointer;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background-color: rgb(42, 87, 155);
  color: white;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  transition: 0.3s ease-in-out;

}

.Schoolid button:hover {
  background-color: rgb(39, 64, 102);

}


.btn {
  align-items: center;
  display: flex;
  justify-content: right;
}

.btn button {
  cursor: pointer;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background-color: #feb602;
  color: black;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  transition: 0.3s ease-in-out;
}

.btn button:hover {
  background-color: #a0760d;
}

@media (max-width: 1250px) {

  .mainContainer {
    border: 2px solid black;
    margin: 0px 20px;
    min-width: 1550px;
    
    
    
  }

  .Calender{
    flex-direction: column;
    margin-bottom: 12px;
  }

  .Schoolid{
    flex-direction: column;
  }
  
}
@media (max-width: 400px) {
  .calendarDropdown{
   padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  input{
    width:80%;
  }
}